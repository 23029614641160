.btnCard {
	position: relative;
	/* display: flex;
	align-items: center;
	gap: 10px; */
	padding: 10px;
	margin-top: 10px;
	width: 100%;
	height: 150px;
	background-color: transparent;
	border: 1px solid #eeeeee;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	box-shadow: 0 0 4px 1px #00000012;
}
.btnCard:hover {
	background-color: #f2f2f2;
}
.btnCard > img {
	width: 100px;
	border-radius: 5px;
	padding-bottom: 4px;
}
.btnCard > .btnRemove {
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 10px;
	background-color: red;
	color: white;
	border: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}

.outlineCardFlex {
	display: flex;
	align-items: center;
	gap: 10px;
}

.detailListing {
margin: 8px 0 0;
}
.detailListing ul {
list-style: none;
padding-left: 0;
margin-bottom: 0;
}
.detailListing li {
	display: inline-block;
    font-size: 14px;
    font-weight: 400;
    padding-right: 10px;
	color: #575A60;

}