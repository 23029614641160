.eventList,
.characterList,
.outlinesList {
  list-style-type: none;
  max-width: 150px;

}

.eventList,
.characterList,
.outlinesList {
  display: flex;
}
.eventList button,
.characterList button,
.outlinesList button {
  min-width: 80px;
  border-radius: 8px;
  padding:5px;

  border: none;
  margin-right: 23px;
  margin-left: -19px;

}
.clicked{
 
   background-color: #1f1717;
  color: white;
}
.notclicked{
  background-color: #f7f7ff;
  color: black;
}
