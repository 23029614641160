.recorder {
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
	justify-content: center;
	padding: 15px 0;
	width: 100%;
	border-radius: 10px;
	background: linear-gradient(135.72deg, #a2d249 1.23%, #388d44 100%);
}
.recorder > button {
	position: relative;
	border: 2px solid #fff;
	background-color: transparent;
	padding: 10px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
}

.recorder > button.glow {
	border: none;
	outline: none;
	cursor: pointer;
	position: relative;
	z-index: 0;
	user-select: none;
	-webkit--user-select: none;
	touch-action: manipulation;
	background-color: transparent;
}
.recorder > button.glow:before {
	content: "glow";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	-webkit-filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	transition: opactiy 0.3s ease-in-out;
	border-radius: 10px;
}
@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
.recorder > button.glow:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #6eae47;
	left: 0;
	top: 0;
	border-radius: 10px;
}

.recorder > button > span,
.track-info .btn-play > span {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	padding-left: 2px;
}
.scale-bar {
	display: flex;
	position: relative;
	justify-content: flex-start;
	width: 900px;
	overflow: auto;
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.scale-bar::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.scale-bar ul {
	display: flex;
	width: 100%;
	align-items: flex-end;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin: 0;
}
.scale-bar ul li {
	display: flex;
	align-items: flex-end;
	transition: height 0.1s ease-in-out;
	color: #fff;
	position: relative;
	height: 60px;
}
.scale-bar ul li + li {
	margin-left: 10px;
}
.scale-bar ul li.small:before,
.scale-bar ul li.big:before {
	content: "";
	position: absolute;
	bottom: 25px;
	width: 2px;
	background-color: #fff;
	left: 50%;
}

.scale-bar ul li.small:before {
	height: 10px;
}
.scale-bar ul li.big:before {
	height: 20px;
}
.track-bar {
	display: flex;
	position: absolute;
	width: 2px;
	height: 100%;
	background-color: #ff000061;
}

.round-player {
	padding: 15px 0;
	width: 100%;
	border-radius: 10px;
	background: linear-gradient(135.72deg, #2db6f5 1.23%, #00519b 100%);
}
.round-player > .player {
	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
	justify-content: center;
}

.btn-track {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px !important;
	height: 22px !important;
	border-radius: 50%;
	background-color: transparent;
	border: 1px solid #ffffff;
}
button:disabled {
	cursor: not-allowed;
}
.visualizer-container {
	border: 1px solid #fff;
	padding: 8px 8px 0;
	border-radius: 10px;
}
.track-info {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
.track-info .btn-play {
	border: 2px solid #fff;
	background-color: transparent;
	padding: 10px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
}
.track-info .btn-delete {
	background-color: transparent;
	color: #fff;
	border: none;
}
