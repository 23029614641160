@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 0;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
  .left-section {
    padding: 30px;
    border-bottom: 0;
  } 
  .right-section {
    padding: 30px;
  } 
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
  .left-section {
    padding: 30px;
    border-right: 0;
    border-bottom: 1px solid #8B8B8B4D;
  } 
  .right-section {
    padding: 30px;
  } 
}

@media only screen and (min-width: 320px) and (max-width: 529px) {
.nw-pt-card .ant-col-8 {max-width: 100%;flex: 0 0 100%;height: 43vh;}
.nw-pt-card .ant-col-16 {max-width: 100%;flex: 0 0 100%;}
.nw-pt-card .ant-col-16 .Card_btnCard__e05BS {height: 100%;}
.nw-pt-card .ant-col-21 {max-width: 100%;flex: 0 0 100%;}
.nw-pt-card .ant-col-3 {max-width: 100%;flex: 0 0 100%;}
.nw-pt-card .ant-col-21 .Chat_sendMessage__LZjqS {margin-right: 0px;margin-bottom: 10px;} 
.Chat_dropdownListings__l9IQS {right: -20px !important;left: 10px !important;}


  .screen-title.mr-3 {
    margin-right: 0;
  }
  .acl-btn-flex button {
    width: 100%;
    margin-right: 0 !important;
  }
  .mobile-flex {
  display: block !important;
  }
  .new-mt {
    margin-top: 15px !important;
  }
  .new-mt img {
    margin-right: 0 !important;
  }
  .drawer-flow {
    width: 100% !important;
  }
  .siderbar-header {
    padding-top: 18px !important;
  }
  .new-cls-cont {
    margin: 0 15px;
  }
.box-pattern {
  display: block !important;
}
 .new-vf-flex {
  margin-bottom: 15px;
 }
.series-box {
  margin-top: 15px !important;
}
.listing-alignment {
  display: block !important;
  margin-top: 17px !important;
}
.listing-alignment a {
  padding: 0 0 10px !important;
  display: block;
}
.spacing-box {
  padding: 30px 0px !important;
}
.new-box-spacing {
  padding: 3rem 0px !important;
}
.res-set-wd {
  width: 100%;
} 
.disp-block {
  display: block !important;
}
.width-full {
  max-width: 100%;
}
.calendar-col-1 {
  min-height: auto;
}
#productivity-image {
margin: 0 auto;
}
.tab-new-container {
  justify-content: center;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 0px;
  width: 100%;
  justify-content: center !important;
}
.screen-book-block {
  display: block !important;
}
.style-left {
  margin-left: 0 !important;
}
.book-contend .sub-title {
  margin-bottom: 10px !important;
}
.bl-dis {display: block;}
.bl-dis .pagination {display: block;}
.card-cont-book {
  padding: 15px 15px;
}
.btn-spacing button {
width: 100%;
}
#book-det-pg .ant-col-6 {
  max-width: 100% !important;
  flex: 0 0 100%;
}
#book-det-pg .ant-row {
  margin-top: 15px;
}

.brain-st-list {
  display: block;
}
.brain-st-list .addnew button {
  width: 100%;
}
.brain-st-list .filter {
  margin-top: 10px;
}
.brain-storm-cls {
  display: block !important;
  margin-bottom: 15px !important;
}
.br-list-line {
  margin-right: 0;
}
.new-card-book-ser {
  display: block !important;
}
.outline-actions {
  display: block !important;
}
.outline-actions button {
  width: 100%;
}
.outline-actions .filter {
  margin-top: 10px;
}
.new-chg .ant-card {
padding: 24px 0px !important;
}
.new-chg .ant-card .ant-card-body {
padding: 24px 15px !important;
}
.site-card-border-less-wrapper .ant-card  {
  padding: 0px 15px !important;
}
.new-chapter-btn button {
  width: 100%;
}
.new-sec-list {
  display: block !important;
}
.new-sec-list button {
width: 100%;
}
.new-sec-list .filter {
margin-top: 15px;
}
.plot-liner {
  display: block !important;
}
.plot-flex-line {
  gap: 10px;
  margin-top: 10px !important;
}
.new-dist-flx {
margin-right: 0 !important;
}
.new-dist-flx p {margin-right: 0 !important;}
.plotLine-btn button {
width: 100%;
}
.plotLine-btn {
  width: 100%;
}
.edit-mark button {
  width: 100%;
}
.edit-mark {
  margin-bottom: 15px;
}
.new-tm-add {
  display: block !important;
}
.new-tm-add .filter {
  margin-top: 15px;
}
.new-tm-add button {
  width: 100%;
}
.jp-flex {
  display: block !important;
}
.box-gap {
  gap: 10px;
}
.box-gap .self-start {
margin-right: 0 !important;
}
.timelineDiv {
  padding: 20px 15px;
}  
#columns-background {
  min-width: auto;
  min-height: auto;
  padding: 20px 15px;
  background-size: cover;
}
#columns-background::before {
  display: none;
}
.chapter-name p {
  margin-right: 0;
}
.chapter-name {
  margin-right: 0 !important;
}
.chapter-name .ml-4 {
  margin-right: 0;
  margin-left: 0;
}
.chapter-name .mr-2 {
  margin-right: 5px;
  border-radius: 50%;
}
.site-card-border-less-wrapper .ant-card-body {
  padding: 15px 15px;
}
.blck-ele {
  display: block !important;
  margin: 10px 0 0;
}
.blck-ele button {
  width: 100%;
  margin-top: 10px;
}
.setting-container {
  margin: 40px 15px;
}
.blck-ele .mr-2 {
  margin-right: 0;
}
.access-table .ant-table-content {
  overflow: auto;
}
.mobile-height {
  height: auto !important;
  }
.header-new-cont {
  display: block !important;
}
.header-new-cont h2 {
  margin-bottom: 10px !important;
  font-size: 20px;
}
.activity-details-container .ant-card-body {
  padding: 24px 15px;
}
.activity-details-container .ant-card {
  padding: 0 !important;
}
.activity-details-container .ant-select {
  width: 100%;
}
.activity-details-container .activity-log-container {
  padding: 20px 0px;
}
.pf-conts {
  margin: 40px 15px;
  padding: 30px 15px;
}
.nm-set {
  display: block !important;
  margin-bottom: 15px;
}
.nm-set .ant-form-item {
  margin-bottom: 10px;
  padding: 0 0 !important;
}
.nm-set button {
  margin-top: 0 !important;
}
.new-h2-head {
  font-size: 18px;
}
.setting-heading .ant-row {
  justify-content: center;
}
.badge-row {margin-right: 0;}
.pf-conts .ant-row {
  justify-content: center;
}
.content-ant .main-container {
  padding: 15px 15px;
}
.new-wrap-add .ant-card-body {
  padding: 15px 0px;
}
.nw-pt-card .ant-card {
width: 100% !important;
padding: 15px 0px !important;
}





  .main-sidebar{
    flex: 0 0 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    width: 65px !important;
  }
  h1 {
    font-size: 20px;
  }
  .act-dsb-slc .ant-select-selection-item {
    font-size: 16px;
  }
  .sub-title {
    font-size: 16px;
  }
  .primary-ant-btn.ant-btn,.secondary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.secondary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus,.secondary-ant-btn.ant-btn:focus{
    font-size: 13px;
  }
  .main-container .act-dsb-slc.ant-select{
    width: 195px !important;
  }
  .primary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus{
    padding: 8px 20px 8px 20px;
    margin: 0 !important; 
  }
  .screen-title{font-size: 20px;}
  .alc-mn{position: relative;padding-top: 55px;}
  .alc-fst{position: absolute;top: 0;left: 0;}
  .alc-mdl{width: 45%;}
  .alc-rt{width: 45%;}
  .ant-menu-inline .ant-menu-item{padding-left: 22px !important;}
  .sidebar-bottom{display: none;}
  .header-rt{
    max-width: auto;
    flex: 0 0 auto;
    border-right: 0;
  }
  .header-lt {
    max-width: auto;
    flex: 0 0 auto;
  }
  .acl-btn-flex{flex-wrap: wrap;}
  .db-slc-flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .acl-pkg{
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .left-section {
    padding: 30px 20px;
  }
  .mob-hide{display: none;}
  .mob-show{display: block;}
}

@media only screen and (min-width: 530px) and (max-width: 767px) {

  .siderbar-header {
    padding-top: 19px !important;
  }
  .login-container {
    height: auto !important;
  }
  .res-set-wd {
    width: 33%;
}
.width-full {
  max-width: 100%;
}
#productivity-image {
margin: 0 auto;
}
.tab-new-container {
  justify-content: center;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 0px;
  width: 100%;
}
.new-calend-rp .ant-card-body {
  padding: 24px 15px;
}
.btn-spacing {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}
#book-det-pg .ant-col-6 {
  max-width: 50% !important;
  flex: 0 0 50%;
}
#book-det-pg .ant-row {
  margin-top: 15px;
}
.br-list-line {
  margin-right: 0;
}
.blck-ele {
  margin: 10px 0 0;
}
.mobile-height {
  height: auto !important;
}
.header-new-cont h2 {
font-size: 20px;
}










  .main-sidebar{
    flex: 0 0 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    width: 65px !important;
  }
  h1 {
    font-size: 20px;
  }
  .act-dsb-slc .ant-select-selection-item {
    font-size: 16px;
  }
  .sub-title {
    font-size: 16px;
  }
  .primary-ant-btn.ant-btn,.secondary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.secondary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus,.secondary-ant-btn.ant-btn:focus{
    font-size: 14px;
    margin: 0 !important;
  }
  .main-container .act-dsb-slc.ant-select{
    width: 195px !important;
  }
  .primary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus{
    padding: 8px 60px 8px 25px;
    margin: 0 !important;
  }
  .screen-title{font-size: 20px;}
  .alc-mdl{width: 20%;}
  .alc-rt{width: 25%;}
  .ant-menu-inline .ant-menu-item{padding-left: 22px !important;}
  .sidebar-bottom{display: none;}
  .header-rt{
    max-width: auto;
    flex: 0 0 auto;
    border-right: 0;
  }
  .header-lt {
    max-width: auto;
    flex: 0 0 auto;
  }
  .acl-btn-flex{flex-wrap: wrap;margin-bottom: 25px;}
  .mob-hide{display: none;}
  .mob-show{display: block;}
}
@media only screen and (min-width:768px) and (max-width: 991px) {
  .res-set-wd {
    width: 33%;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 0px;
  width: 100%;
}
.new-calend-rp .ant-card-body {
  padding: 24px 15px;
}
.btn-spacing {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.btn-spacing {margin: 0 0 0;}
#book-det-pg .ant-col-6 {
  max-width: 50% !important;
  flex: 0 0 50%;
}
#book-det-pg .ant-row {
  margin-top: 15px;
}
.mobile-height {
  height: auto !important;
}
.header-new-cont h2 {
  font-size: 20px;
}




  .main-sidebar{
    flex: 0 0 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    width: 65px !important;
  }
  h1 {
    font-size: 20px;
  }
  .act-dsb-slc .ant-select-selection-item {
    font-size: 16px;
  }
  .sub-title {
    font-size: 16px;
  }
  .primary-ant-btn.ant-btn,.secondary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.secondary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus,.secondary-ant-btn.ant-btn:focus{
    font-size: 14px;
  }
  .main-container .act-dsb-slc.ant-select{
    width: 195px !important;
  }
  .primary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus{
    padding: 8px 60px 8px 25px;
    margin: 0;
  }
  .screen-title{font-size: 22px;}
  .alc-mdl{width: 20%;}
  .alc-rt{width: 25%;}
  .ant-menu-inline .ant-menu-item{padding-left: 22px !important;}
  .sidebar-bottom{display: none;}
  .header-rt{
    max-width: 70%;
  }
  .header-lt {
    max-width: 26%;
    flex: 0 0 26%;
  }
}
@media only screen and (min-width:992px) and (max-width: 1199px) {
  .res-set-wd {
    width: 33%;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 0px;
  width: 100%;
}
.new-calend-rp .ant-card-body {
  padding: 24px 15px;
}
.btn-spacing {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.style-left {
  margin-left: 73% !important;
}
.activity-flex .ml-5 {
  margin-left: 0 !important;
  padding: 0px 15px;
}
.activity-flex .mr-5 {
  margin-right: 0 !important;
}
#book-det-pg .ant-col-6 {
  max-width: 50% !important;
  flex: 0 0 50%;
}
#book-det-pg .ant-row {
  margin-top: 15px;
}
.new-chg .ant-col-lg-6 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}
.chapter-name {
  margin-right: 0 !important;
}
.chapter-name .mr-3 {
  margin-right: 0;
}
.mobile-height {
  height: auto !important;
}
  









  h1 {
    font-size: 20px;
  }
  .act-dsb-slc .ant-select-selection-item {
    font-size: 16px;
  }
  .sub-title {
    font-size: 16px;
  }
  .primary-ant-btn.ant-btn,.secondary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.secondary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus,.secondary-ant-btn.ant-btn:focus{
    font-size: 14px;
  }
  .main-container .act-dsb-slc.ant-select{
    width: 195px !important;
  }
  .primary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus{
    padding: 8px 60px 8px 25px;
    margin: 0;
  }
  .screen-title{font-size: 22px;}
  .activity-log-container .display-flex{
    flex-wrap: wrap;
    gap: 12px;
  }
  .alc-mdl{width: 40%;}
  .alc-rt{width: 45%;}
}
@media only screen and (min-width:1260px) and (max-width: 1365px) {
  h1 {
    font-size: 22px;
  }
  .act-dsb-slc .ant-select-selection-item {
    font-size: 16px;
  }
  .sub-title {
    font-size: 17px;
  }
  .primary-ant-btn.ant-btn,.secondary-ant-btn.ant-btn,.primary-ant-btn.ant-btn:hover,.secondary-ant-btn.ant-btn:hover,.primary-ant-btn.ant-btn:focus,.secondary-ant-btn.ant-btn:focus{
    font-size: 15px;
  }
  .main-container .act-dsb-slc.ant-select{
    width: 195px !important;
  }
  .new-chg .ant-col-lg-6 {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }
  .chapter-name .mr-3 {
    margin-right: 0;
  }
  .mobile-height {
    height: auto !important;
  }






} 
@media only screen and (min-width:1366px) and (max-width: 1439px) {
  h1 {
    font-size: 24px;
  }

  .new-chg .ant-col-lg-6 {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }
  .mobile-height {
    height: auto !important;
  }


}
@media only screen and (min-width: 1440px) and (max-width: 1599px){
  .mobile-height {
    height: auto !important;
  }
  
}
@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .mobile-height {
    height: auto !important;
  }
}
@media only screen and (min-width: 1680px) and (max-width: 1919px){
  .mobile-height {
    height: 100vh !important;
  }
}
@media only screen and (min-width: 2560px) {
}
