.chatContainer {
	padding: 20px;
}
.contacts {
	overflow-y: auto;
	border: 1px solid #ebe9f1;
	background-color: #ffffff;
	border-radius: 6px 0px 0px 6px;
	-webkit-border-radius: 6px 0px 0px 6px;
	-moz-border-radius: 6px 0px 0px 6px;
}
.chat {
	border: 1px solid #ebe9f1;
	border-left: none;
	background-color: #f7f7f7;
	border-radius: 0px 6px 6px 0px;
	-webkit-border-radius: 0px 6px 6px 0px;
	-moz-border-radius: 0px 6px 6px 0px;
}
.contacts,
.chat {
	height: 88vh;
}
.contactsHeader {
	display: flex;
	gap: 20px;
	align-items: center;
	border-bottom: 1px solid #ebe9f1;
	padding: 10px 20px;
	height: 71px;
}
.chatHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ffffff;
	border-bottom: 1px solid #ebe9f1;
	padding: 10px 20px;
	height: 71px;
}
.contactsHeading {
	font-family: Inter;
	font-size: 18px;
	font-weight: 100;
	line-height: 22px;
	color: #7367F0;
	font-weight: 500;
	padding: 0 20px;
}
.contactsList {
	display: flex;
	flex-direction: column;
	align-items: self-start;
	gap: 10px;
}
.contact {
	display: flex;
	gap: 15px;
	align-items: center;
	cursor: pointer;
}
.contactItem {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	border: none;
	background-color: transparent;
}
.contactItem:hover {
	background-color: #f8f8f8;
}
.chatInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 5px;
}
.contactName {
	font-family: Inter;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	color: #201f27;
}
.contactMessage {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #a2a4a8;
	max-width: 200px;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.chatInfo > .chatTime {
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: #a2a4a8;
}
.chatInfo > .chatCount {
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	color: #ffffff;
	background-color: #ea5455;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contactImage {
	position: relative;
	width: max-content;
}
.chatDetails {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.contactImage > div > img {
	width: 40px !important;
	height: 40px !important;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}
.onlineMarker {
	position: absolute;
	width: 15px;
	height: 15px;
	background-color: #26c46c;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border: 2px solid #ffffff;
	right: -5px;
	top: 25px;
}
.contactItemSelected {
	background: linear-gradient(46.62deg, #4b63e1 0%, #6e8eff 93.64%);
}
/* .contactItemSelected > .chatInfo > .chatCount {
	background-color: #ffffff;
	color: #ea5455;
} */
.contactItemSelected > .chatInfo > .chatTime,
.contactItemSelected > .contact > .chatDetails > p {
	color: #ffffff;
	font-weight: 700;
}
.chatUserInfo {
	display: flex;
	align-items: center;
	gap: 10px;
}
.chatUserInfo > .avatar {
	position: relative;
}
.chatUserInfo > .avatar > div > img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}
.chatUserInfo > .name {
	font-family: Inter;
	font-size: 15px;
	font-weight: 700;
	line-height: 24px;
}
.chatActions {
	display: flex;
	gap: 10px;
}
.chatBody {
	position: relative;
	margin-top: 20px;
	height: 64vh;
	padding-bottom: 20px;
	overflow-y: auto;
}
.chatMessage {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	margin: 0 20px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
}
.chatMessage + .chatMessage {
	margin-top: 10px;
}
.chatMessageMy {
	flex-direction: row-reverse;
}
.chatMessageMy > .messageParent > .messagesParent> .message {
	position: relative;
	background: linear-gradient(46.62deg, #4b63e1 0%, #6e8eff 93.64%);
	text-align: right;
	color: white;
}
.chatMessage > .messageParent > .messagesParent> .message {
	
	position: relative;
	background-color: #ffffff;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	padding: 10px 15px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	-moz-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
}
.chatMessage > .messageParent > .message > .reactions {
	display: flex;
	justify-content: flex-end;
	padding: 0 5px;
	background-color: white;
	border-radius: 10px;
	gap: 5px;
	border: 1px solid #eee;
}
.chatMessage > .messageParent > .message > .reactions > div {
	font-size: 8px;
	color: black;
}
.chatFooter {
	padding: 10px 20px;
	background-color: #ffffff;
	border-top: 1px solid #ebe9f1;
}
.sendMessage {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 20px;
	border: 1px solid #ebe9f1;
	margin-right: 10px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.chatInput {
	width: 100%;
	border: none;
	background-color: transparent;
	outline: none;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #333333;
}
.chatSendBtn {
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 17px;
	letter-spacing: 0.4000000059604645px;
	text-align: center;
	color: #ffffff;
	background-color: #3a59fc;
	border: none;
	padding: 20px 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	width: 100%;
}
.columnSendMsg {
	display: flex;
	align-items: flex-end;
}
.newGroup {
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
}
.searchContacts {
	display: flex;
}

.searchContacts > button {
	border: none;
	background-color: transparent;
	display: flex;
	width: 100%;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	padding: 5px;
}

.searchContacts > button:hover {
	background-color: #f8f8f8;
}
.contactSelected {
	background-color: #f2f2f2 !important;
}
.searchContacts > button > div > div > img {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
}
.btnAnchor {
	background-color: transparent;
	border: none;
	cursor: pointer;
}
.chatCommand {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.chatCommand > button {
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}
.chatCommand > button > .commandIcon {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	background-color: #201f27;
}
.chatCommand > button > .commandIcon .plus {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 22px;
	font-size: 18px;
}
.chatCommand > button .title {
	font-size: 14px;
	font-weight: bold;
}
.chatCommand > button .description {
	font-size: 13px;
	color: #a2a4a8;
}
.attachmentshedFiles {
	display: flex;
	max-width: 100%;
	overflow: auto;
	gap: 5px;
	padding: 5px;
}
.attachedThumbnail {
	display: flex;
	align-items: center;
	width: 50px;
	height: 50px;
	padding: 2px;
	border: 1px solid #ccc;
}
.attachedThumbnail > div > img {
	max-width: 50px;
	max-height: 50px;
}
.featureContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	position: absolute;
	bottom: 90px;
	left: 10px;
}

.attachedFiles {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	position: absolute;
	bottom: 20px;
	left: 10px;
}


.messagesParent {
	position: relative;
	 display: flex;
	align-items: center;
	gap: 20px; 
	margin-bottom: 25px !important;
}

.reactionButtons{
	position: absolute;
	top: -65px;
	z-index: 999;
	background-color: #FFF;
	padding: 10px 25px;
	border-radius: 100px;
	gap: 20px;
	-webkit-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	-moz-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
}

.reactionButtonsAll{
	width: 20px;
	height: 20px;
	outline: none;
	border: none;
	background-color: transparent;
}

.reactionButtonsAll img{
	width: 20px;
	height: 20px;
	object-fit: contain;
}

.reactionButtonDots{
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffffff;
	position: absolute;
	bottom: -23px;
	left: 54%;
	transform: translateY(-50%);
	-webkit-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	-moz-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
}

.reactionButtonDotsO{
	width: 15px;
	height: 10px;
	border-radius: 100px;
	background-color: #ffffff;
	position: absolute;
	bottom: -9px;
	left: 50%;
	transform: translateY(-50%);
	-webkit-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	-moz-box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
	box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
}

.dropdownListings {
	position: absolute;
    list-style: none;
    padding-left: 0;
    background-color: #FCFCFC;
    min-width: 200px;
    border-radius: 12px;
    box-shadow: 0px 6px 21px -7px rgba(207, 207, 207, 1);
    margin-bottom: 0;
    bottom: -120px;
    z-index: 1;
}
.dropdownListings li {
	border: 1px solid #ECEBEB;
	padding: 8px 10px;
	font-size: 14px;
	font-weight: 500;
	color: #201F27;
	display: flex;
    align-items: center;
    gap: 15px;
	transition: 0.6s all ease-in-out;
}
.dropdownListings li:hover {
	color: #FF3742;
	cursor: pointer;
}
.dropdownListings li:hover img {
	filter: grayscale(100%) brightness(100%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}
.chatReplyBox {
	background-color: #8080801c;
    padding: 10px 20px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
	margin-right: 10px;
	position: relative;
	overflow: hidden;
}
.leftBarStyle {
	margin: 0 0 0 auto;
    display: table;
}
.crossIconMark {
position: absolute;
top: 0;
left: 0;
width: 5px;
height: 100vh;
background-color: #000;
}
.chatReplyText {
	font-weight: 400;
    font-size: 15px;
}
.boxHeadingText {
	font-size: 16px;
    font-weight: 700;
}
.reactionEmoji {
	position: absolute;
    bottom: -18px;
    right: 2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    box-shadow: 0 0 3px 2px #00000021;
}
.reactionEmoji img {
	filter: grayscale(100%) brightness(70%) sepia(100%) hue-rotate(-50deg) saturate(800%) contrast(0.8);
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.yesterdatTextBar {
	background-color: #fff;
    padding: 5px 5px;
    border-radius: 6px;
    width: 100px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin: 0 auto;
    display: block;
    position: relative;
	z-index: 1;

}
.parentLeftBar {
	position: absolute;
    top: 15px;
    left: 23px;
    width: 96%;
    height: 2px;
    background-color: #fff;
    z-index: 0;
}
.parentRightBar {
	position: absolute;
    top: 15px;
    right: 23px;
    width: 96%;
    height: 2px;
    background-color: #fff;
    z-index: 0;
}
.parentChatDiv {
	position: relative;
}
.outlineFlexBox {
display: flex;
align-items: center;
}

.chatModalWrapper{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #B8B8B833;
	backdrop-filter: blur(3px);
	border-radius: 6px;
	z-index: 1;
	pointer-events: none;
	overflow: hidden;
	opacity: 0;
	transition: 0.6s all ease-in-out;
}

.chatModalWrapper.active{
	opacity: 1;
}

.chatContainerRow{
	position: relative;
	border-radius: 6px;
	box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
}

.chatModalLeft{
	width: 33.333333%;
	height: 100%;
	background-color: #FFF;
	box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	transition: 0.6s all ease-in-out;
	position: absolute;
	left: -100%;
	top: 0;
	padding: 50px 30px;
	overflow-y: auto;
}

.chatModalLeft.active{
	left: 0;
}

.chatModalRight{
	width: 33.333333%;
	height: 100%;
	background-color: #FFF;
	box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	-moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.54);
	transition: 0.6s all ease-in-out;
	position: absolute;
	right: -100%;
	top: 0;
	padding: 50px 30px;
	overflow-y: auto;
}

.leftModalCloseIcon{
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
}

.leftModalCloseIcon{
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
}

.leftModalHead{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.leftModalHeadAvatar{
	position: relative;
}

.leftModalHeadAvatar img{
	width: 100px !important;
	height: 100px !important;
	border-radius: 50%;
	object-fit: cover;
}

.leftModalHead span{
	display: block;
	width: 25px;
	height: 25px;
	background-color: #28C76F;
	border-radius: 50%;
	position: absolute;
	right: 5px;
	bottom: 0px;
	border: 4px solid #FFF;
}

.leftModalHead h5{
	font-size: 18px;
	font-weight: 700;
	font-family: Inter;
	color: #201F27;
	margin-top: 10px;
}

.leftModalHead h6{
	font-size: 14px;
	font-weight: 400;
	font-family: Inter;
	color: #6E6B7B;
	margin-top: 3px;
}

.leftModalAbout{
	padding: 30px 0;
}

.leftModalAbout h6{
	font-size: 12px;
	font-weight: 600;
	font-family: Inter;
	color: #575A60;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}

.leftModalAbout div{
	border: 1px solid #D8D6DE;
	padding: 10px 15px 30px 15px;
	border-radius: 6px;
}

.leftModalAbout p{
	font-size: 14px;
	font-weight: 400;
	font-family: Inter;
	color: #575A60;
	line-height: 22px;
}

.leftModalStatus{
	padding: 0px 0 20px 0;
}

.leftModalStatus h6{
	font-size: 12px;
	font-weight: 600;
	font-family: Inter;
	color: #575A60;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}

.leftModalStatus div{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.leftModalStatus label{
	display: flex;
	align-items: center;
	gap: 10px;
	padding-bottom: 10px;
	font-size: 14px;
	font-weight: 400;
	font-family: Inter;
	color: #6E6B7B;
	cursor: pointer;
}

.leftModalStatus label span{
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: transparent;
	border: 1px solid #D8D6DE;
}

.leftModalStatus label input{
	display: none;
}

.leftModalStatus label input:checked + span {
    background-color: #28C76F;
}

.leftModalSettings h6{
	font-size: 12px;
	font-weight: 500;
	font-family: Inter;
	color: #B9B9C3;
	margin-bottom: 20px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}

.rightModalSettings{
	padding-bottom: 15px;
}

.rightModalSettings h6{
	color: #575A60;
}

.leftModalSettingsDiv{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 12px;
}

.leftModalSettingsDivLeft{
	display: flex;
    align-items: center;
    gap: 10px;
	font-size: 14px;
	font-weight: 400;
	font-family: Inter;
	color: #6E6B7B;
}

.leftModalSettingsDivLeft a{
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: none;
	color: #6E6B7B;
}

.leftModalSettingsDivLeft a:hover{
	color: #6E6B7B;
}

.leftModalBtn{
	padding-top: 30px;
}

.leftModalBtn a{
	padding: 10px 25px; 
	color: #FFFF;
	background-color: #3A5AFF;
	border-radius: 6px;
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	font-family: Inter;
}

.switchContainer {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 5px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked + .slider {
    background-color: #3A5AFF;
}

.switch input:focus + .slider {
    box-shadow: 0 0 1px #3A5AFF;
}

.switch input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.grd_img{
display: grid;
grid-row-gap: 3px;
}
.grid_col{
	grid-column-gap: 3px;
}

.more_img {
	position: relative;
}
.more_img > :nth-child(2) {
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
background-color: #00000075;
display: none;
justify-content: center;
align-items: center;
border-radius: 5px;
}

