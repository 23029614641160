
.button-active {
  background: linear-gradient(45deg, #03c17d 0%, #97e81c 100%);
  width: 9px;
  height: 9px;
  border-radius: 10px;
  margin: 10px;
  display: inline;
  border: none;
}
h2 {
  font-weight: 700;
  margin-bottom: 0px !important;
}

.close {
  color: black !important;
  right: 10px;
  margin-left: 40px;
  font-size: large;
  z-index: 999;
}
.para {
  color: #5c606b;
  width: 80%;
}
.label {
  font-family: "Inter SemiBold";
  color: #202127;
}

// .ant-select-selector {
//   height: 50px !important;
//   align-items: center;
//   border-radius: 6px !important;
//   font-family: "Inter SemiBold";
//   margin-top: 10px;
//   font-weight: 700;
// }
// .ant-select-arrow {
//   color: #175bff;
//   font-size: 17px;
//   margin-top: 0px !important;
// }
// .ant-input-prefix {
//   margin-right: 8px;
//   color: #a2a4a8 !important;
//   font-weight: 600;
//   font-size: 16px !important;
// }
// .ant-input,
// .ant-select {
//   color: black;
// }

// .ant-input-affix-wrapper {
//   border-radius: 6px !important;
// }
label {
  font-family: "Inter SemiBold";
}

.text {
  padding: 12px;
  font-family: 'Inter Regular';
  font-weight: 400;
}
.ant-upload-drag-icon {
  border: 2px dashed rgba(139, 139, 139, 0.3);
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #ffffff;
  width: 112px;
  height: 112px;
  margin: auto !important;
  align-items: center !important;
  display: flex;
  justify-content: center !important;
}
.upload {
  border: 1px solid rgba(139, 139, 139, 0.3) !important;
  border-radius: 8px;
  background-color: #fafbfc;
}
.ant-upload-drag-icon img {
  color: rgba(139, 139, 139, 0.3) !important;
}

.upload-label {
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  color: #5c606b;
}

.change {
  background-color: #3a5aff;
  letter-spacing: 0.6px;
  margin-right: 15px;
  font-size: 16px;
  height: 56px;
  width: 100%;

}
.remove {
  border-color: #3a5aff;
  color: #3a5aff;
  font-size: 16px;
  height: 56px;
  width: 100%;
}
