*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

$contW: 500px;
$imgW: 360px;
$formW: $contW - $imgW;
$switchAT: 1.2s;

$inputW: 260px;
$btnH: 36px;

$diffRatio: ($contW - $imgW) / $contW;

@mixin signUpActive {
  .cont.s--signup & {
    @content;
  }
}
.signin_heading {
  text-align: center;
}
.login-container {
  background: linear-gradient(135.72deg, #2db6f5 1.23%, #00519b 100%);

  background-size: cover;
  margin: 0;
  padding: 0;
  width: 100vw;
  background-position: center;
  height: 100vh;
}
.ques {
  text-align: center;
  margin-bottom: 10px;
  color: white;
  font-size: 25px;
}
.cont {
  overflow: hidden;
  position: relative;
  width: $contW;
  max-height: 500px;
  // height: 90vh;
  display: flex;
  justify-content: center;
  background: #fff;
  top: 100px;
  border-radius: 10px;
  box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform $switchAT ease-in-out;
  padding: 50px 30px 0;
}

.sub-cont {
  overflow: hidden;
  position: absolute;
  left: $formW;
  top: 0;
  width: $contW;
  height: 100%;
  padding-left: $imgW;
  background: #fff;
  transition: transform $switchAT ease-in-out;

  @include signUpActive {
    transform: translate3d($formW * -1, 0, 0);
  }
}

// button {
//   display: block;
//   margin: 0 auto;
//   width: $inputW;
//   height: $btnH;
//   border-radius: 30px;
//   color: #fff;
//   font-size: 15px;
//   cursor: pointer;
// }

.img {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: $imgW;
  height: 100%;
  padding-top: 360px;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: $contW;
    height: 100%;
    background-image: url("../../assets/images/bg-signup.jpg");
    background-size: cover;
    transition: transform $switchAT ease-in-out;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @include signUpActive {
    &:before {
      transform: translate3d($formW, 0, 0);
    }
  }

  &__text {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    color: #fff;
    transition: transform $switchAT ease-in-out;

    h2 {
      margin-bottom: 10px;
      font-weight: normal;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
    }

    &.m--up {
      @include signUpActive {
        transform: translateX($imgW * 2);
      }
    }

    &.m--in {
      transform: translateX($imgW * -2);

      @include signUpActive {
        transform: translateX(0);
      }
    }
  }

  &__btn {
    overflow: hidden;
    z-index: 2;
    position: relative;
    width: 100px;
    height: $btnH;
    margin: 0 auto;
    background: transparent;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;

    &:after {
      content: "";
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid #fff;
      border-radius: 30px;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: transform $switchAT;

      &.m--in {
        transform: translateY($btnH * -2);

        @include signUpActive {
          transform: translateY(0);
        }
      }

      &.m--up {
        @include signUpActive {
          transform: translateY($btnH * 2);
        }
      }
    }
  }
}

// label {
//   display: block;
//   width: $inputW;
//   margin: 25px auto 0;
//   text-align: center;

//   span {
//     font-size: 12px;
//     color: #cfcfcf;
//     text-transform: uppercase;
//   }
// }

input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.submit {
  margin-bottom: 30px;
  margin-top: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: none;
  padding: 8px;
  border-radius: 8px;

  background-color: #1677ff;
  color: white;
  width: 350px;
}
#sign-up {
  display: flex;
  justify-content: center;
}
.sign-up {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  border: #fff;
  background-color: rgb(253, 253, 253);
  cursor: pointer;
}
.signin-img {
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30rem;
  background-image: url("../../assets/images/bg-signup.jpg");
  min-height: 90vh;
}
#s_up {
  position: relative;
  margin-top: 40px;
}
