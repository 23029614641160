.display-flex {
	display: flex !important;
	align-items: center;
}
.sidebar-container {
	padding: 40px 0 !important;
}
.mb-110px {
	margin-bottom: 110px;
}
.mt-48px {
	margin-top: 48px;
}
body {
	overflow-y: auto;
}

.logout {
	border: none;
	color: blue;
	background-color: transparent;
	transform: all 0.2s;
}
.location_name {
	font-weight: bold;
	font-size: 18px;
	text-transform: capitalize;
	color: #1890ff;
}
#profile_image {
	border-radius: 50%;
}

.form-input .required,
.form-input .error {
	color: red;
}
iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

.selected-users {
	max-height: 200px;
	overflow: auto;
}
.selected-users .user {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	border: 1px solid #eeeeee;
	padding: 5px;
	border-radius: 10px;
	cursor: pointer;
}
.selected-users .user img {
	max-width: 50px;
	max-height: 50px;
	border-radius: 50%;
	border: 1px solid #cccccc;
}
.selected-users.selected {
	margin-top: 10px;
	background-color: #eeeeee;
}
.selected-users.selected .user p {
	color: #1890ff;
}
